import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useGLTF_cloned from "../loaders/useGLTF_cloned.js";
import {
  Center,
  Text,
  Text3D,
  useAnimations,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import * as THREE from "three";
import { useModelsStore } from "../components/ModelsStore.js";
import { useFrame } from "@react-three/fiber";

const TrackContext = createContext();
export const useTrackContext = () => useContext(TrackContext);

const trackpiece_per100m = 1;
const sx = 1;
export const metrics = {
  bike: {
    scale: 1,
    between: 0.1,
    width: 0.5,
  },
  text_emissive_intensity: 0.5,
  sx,
};


const TrackPiece = ({ i = 0, ...props }) => {
  const { models } = useTrackContext();
  const trackpiece = useGLTF_cloned(models.trackpiece);
  const { animations, scene } = trackpiece;
  const { actions } = useAnimations(animations, scene);
  useEffect(() => {
    animations?.forEach((clip) => {
      actions[clip?.name]?.play();
      console.log(clip?.name);
    });
    // console.log(trackpiece.animations, trackpiece, actions);
  }, [trackpiece, animations, actions]);
  // console.log(box1);
  const posx = (i * 100) / trackpiece_per100m;
  return (
    <group position-x={posx}>
      <primitive object={trackpiece.scene} />
    </group>
  );
  return null;
};

const FloorMark = ({ atdist, txt = "aaaa", ...props }) => {
  const atglobx = atdist * sx;
  const neon = "#00FFFF";
  const textRef = useRef();
  useFrame(({ clock }) => {
    if (textRef.current) {
      textRef.current.rotation.y = clock.elapsedTime * 1.5;
      const my_scale = Math.sin(clock.elapsedTime) / 2 + 2.5;
      textRef.current.position.y = my_scale;
      // textRef.current.scale.x = my_scale;
      // textRef.current.scale.y = my_scale;
      // textRef.current.scale.z = my_scale;

      // textRef.current.rotation.x += 0.1;
    }
  });
  const text3d = (
    <Text3D
      font="./fonts/Audiowide_Regular.json"
      size={0.5}
      height={0.05}
      curveSegments={12}
      bevelEnabled
      bevelThickness={0.02}
      bevelSize={0.02}
      bevelSegments={5}
    >
      {txt}
      <meshStandardMaterial
        color={new THREE.Color("#ff0000")}
        emissive={new THREE.Color(neon)}
        emissiveIntensity={metrics.text_emissive_intensity}
      />
    </Text3D>
  );
  return (
    <group position-x={atglobx}>
      <group position-y={2.5} ref={textRef}>
        <Center
          // sa
          position-z={0}
          position-x={-0.1}
          rotation-y={degToRad(-90)}
        >
          {text3d}
        </Center>
        <mesh>
          <boxGeometry args={[0.01, 1.8, 3]} />
          <meshBasicMaterial color={"#000000"} />
        </mesh>

        <Center position-z={0} position-x={+0.1} rotation-y={degToRad(90)}>
          {text3d}
        </Center>
      </group>
      <>
        <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={1}
          height={0.05}
          curveSegments={12}
          text3d
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
          position-y={5}
          position-z={-8}
        >
          {txt}
          <meshStandardMaterial
            color={new THREE.Color("#ff0000")}
            emissive={new THREE.Color(neon)}
            emissiveIntensity={metrics.text_emissive_intensity}
          />
        </Text3D>
      </>
    </group>
  );
};
const FinishLine = ({ atdist }) => {
  const atglob = atdist * sx;
  const neon = "#00ffff";
  const size = { x: 9.5, y: 4.5 };
  const checksize = { x: 0.1, y: 0.1 };

  const neontexture = useTexture("/images/neon_checkbox.png");
  const neontexture_alpha = useTexture("/images/neon_checkbox_alpha.jpg");
  for (let t of [neontexture, neontexture_alpha]) {
    t.repeat.set(size.x / checksize.x, size.y / checksize.y);
    t.wrapS = THREE.RepeatWrapping;
    t.wrapT = THREE.RepeatWrapping;
  }

  return (
    <group
      position-x={atglob}
      position-z={0}
      position-y={0}
      rotation-y={degToRad(-90)}
    >
      <mesh>
        <boxGeometry args={[size.x, size.y, 0.05]} />
        <meshStandardMaterial
          map={neontexture}
          alphaMap={neontexture_alpha}
          // emissiveMap={neontexture_emissive}
          emissive={new THREE.Color(neon)}
          emissiveIntensity={metrics.text_emissive_intensity}
          transparent={true}
        />
      </mesh>
    </group>
  );
};
const StartLine = () => {
  const modelstore = useModelsStore();
  const stline = useGLTF_cloned(modelstore.get_model("startline_gates"));
  return (
    <group>
      <group
        rotation={[0, Math.PI / 2, 0]}
        position={[0, 0.05, 0]}
        scale={[1.4, 1.4, 0.1]}
      >
        <primitive object={stline.scene} />
      </group>
      <Center position-x={-2.5} rotation-y={degToRad(90)} position-y={1.5}>
        {/*  <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={0.5}
          height={0.02}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
        >
          {"START"}
          <meshStandardMaterial
            color={new THREE.Color("#00ffff")}
            emissive={new THREE.Color("#00ffff")}
            emissiveIntensity={metrics.text_emissive_intensity}
          />
        </Text3D> */}
      </Center>
      <mesh position={[0, 0.05, 0]} rotation={[degToRad(-90), 0, 0]}>
        <planeGeometry args={[0.2, 7.4]} />
        <meshBasicMaterial color={"#ffffff"} />
      </mesh>
    </group>
  );
};

const Billboard = ({ atdist }) => {
  const { models } = useTrackContext();
  const bm = useGLTF_cloned(models.billboard);
  return (
    <group
      position-x={atdist * sx}
      position-z={-10}
      position-y={0}
      rotation-y={degToRad(-75)}
    >
      <group scale={0.3}>
        <primitive object={bm.scene} />
      </group>
    </group>
  );
};

function Track({ dist = 2200, ...props }) {
  const models_store = useModelsStore();
  const trackpiece_gltf = models_store.get_model("track_trackpiece");

  const models = {
    trackpiece: trackpiece_gltf,
  };

  const tcon = {
    models,
  };

  const trackpieces_n = useMemo(() => {
    let seg = 100;
    let n = dist / seg + 1 + 10;
    n = n * trackpiece_per100m;
    return n;
  }, [dist]);

  const floor_marks = useMemo(() => {
    let seg = 100;
    let n = dist / seg + 1;
    let ar = [...Array(n)].map((e, i) => {
      let d = i * seg;
      let dtxt =
        (d == 0 && "START") || (d == dist && "FINISH") || `${dist - d}M`;

      return [d, dtxt];
    });
    ar = ar.slice(1);
    return ar;
  }, [dist]);

  const planelen = (trackpieces_n * 100) / trackpiece_per100m;

  return (
    <TrackContext.Provider value={tcon}>
      <StartLine />

      {floor_marks.map(([atdist, txt], i) => {
        return (
          <FloorMark
            {...{
              key: i,
              atdist: atdist,
              txt,
            }}
          />
        );
      })}
      {[...Array(trackpieces_n)].map((e, i) => (
        <TrackPiece {...{ key: i, i: i - 10 }} />
      ))}

      <FinishLine atdist={dist} />

      {/*  <directionalLight position={[0, 20, 0]} intensity={0.4} />
      <directionalLight position={[0, 20, -20]} intensity={0.15} />
      <directionalLight position={[0, 25, 60]} intensity={0.15} />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[5, 0, 50]}
        intensity={0.05}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[0, 0, -50]}
        intensity={0.3}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[-5, 0, -50]}
        intensity={0.05}
        color="#ffffff"
      /> */}
    </TrackContext.Provider>
  );
}

export default Track;
