import { useGLTF } from "@react-three/drei";
import _ from "lodash";
import React, { useMemo } from "react";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import { nils } from "../utils/utils.js";

function useGLTF_cloned(gltf) {
  const g2 = useMemo(() => {
    if (nils(gltf) || nils(gltf.scene))
      return {
        scene: {},
        animations: [],
      };
    let { nodes, materials, animations, scene } = gltf;
    nodes = _.cloneDeep(nodes);
    scene = clone(scene);
    const g2 = { ...gltf, nodes, materials, animations, scene };
    return g2;
  }, [gltf]);

  return g2;
}

export default useGLTF_cloned;
