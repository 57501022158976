import { useLoader } from "@react-three/fiber";
import {
  EffectComposer,
  SSR,
  Bloom,
  LUT,
  DepthOfField,
  Glitch,
} from "@react-three/postprocessing";
import { LUTCubeLoader } from "postprocessing";

export function Effects({ applyglitch }) {
  const texture = useLoader(LUTCubeLoader, "/lut/F-6800-STD.cube");

  const { enabled, ...props } = {
    enabled: true,
    // temporalResolve: false,
    STRETCH_MISSED_RAYS: true,
    USE_MRT: false,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: false,
    // ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    temporalResolveMix: 0.4,
    temporalResolveCorrectionMix: 1.0,
    maxSamples: 0.1,
    resolutionScale: 1,
    blurMix: 1,
    blurExponent: 0.1,
    blurKernelSize: 10,
    blurSharpness: 10,
    rayStep: 15,
    intensity: 0.1,
    maxDepth: 0.2,
    // jitter: 0.1,
    // jitterSpread: 0.2,
    // jitterRough: 0,
    // roughnessFadeOut: 1,
    rayFadeOut: 1,
    // MAX_STEPS: 5,
    // ior: 1.3,
  };
  return (
    enabled && (
      <EffectComposer>
        {/* <SSR {...props} /> */}
        <Bloom
          luminanceThreshold={0.6}
          height={1000}
          luminanceSmoothing={0.5}
          intensity={0.1}
        />
        {/* <LUT lut={texture} /> */}
        {/* <DepthOfField /> */}
        {applyglitch && <Glitch strength={applyglitch} />}
      </EffectComposer>
    )
  );
}
