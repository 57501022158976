import { create as createZustand } from "zustand";
import {
  iserr,
  q_arcade_race,
  q_arcade_race_hstats,
  q_arcade_racerun,
  q_bikeinfo,
  q_race,
  q_racerun,
  qiserr,
  qissuccesss,
  useStepQuery,
} from "../utils/queries.js";
import { base64_to_json, getv, jstr, nano, nils } from "../utils/utils.js";
import { useQueries } from "react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useAppContext, useNowContext } from "../App.js";
import { useFrame } from "@react-three/fiber";
import { Helmet } from "react-helmet-async";

const startoffset = [10, "seconds"];

export const useRace = createZustand((set) => ({
  loaded: false,
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));

export const get_race_rtstatus = (r, now) => {
  if (nils(r)) return [null, null, null];
  let { start_time, end_time, status } = r;
  let st = nano(start_time);
  let ed = nils(end_time) ? st + 1e3 * 200 : nano(end_time);
  if (status == "cancelled") return ["cancelled", null, null];
  if (status == "open") return ["open", null, null];

  if (now > ed) return ["finished", null, null];

  let rem_st = parseInt((st - now) / 1000);
  let rem_ed = parseInt((ed - now) / 1000);

  if (_.inRange(now, st, ed) || rem_st < 10) return ["live", rem_st, rem_ed];

  if (now < st) return ["scheduled", rem_st, rem_ed];

  return [null, null, null];
};

const skinmap = _.chain([
  ["exclusive", "MMRED", "#FFFFFF"],
  ["exclusive", "MMBLUE", "#FFFFFF"],
])
  .map((e) => {
    return {
      skin: e[1],
      name: e[1],
      acc: e[2],
      rarity: e[0],
    };
  })
  .keyBy("skin")
  .value();

export const Race = () => {
  const acon = useAppContext();
  const { now } = useNowContext();
  const { psearch } = acon;

  const is_arcade = psearch.is_arcade == "true";

  const racect = useRace();
  const hsrefs = useRef([]);

  const rid = useMemo(() => {
    let p = acon.psearch;
    let rid = getv(p, "race_id");
    return rid;
  }, [acon.psearch]);

  const [watching2d, set_watching2d] = useState(
    ["true", true].includes(psearch.watching2d),
  );
  const [watching2d_st, set_watching2d_st] = useState(null);
  const [race_err, set_race_err] = useState(null);

  const [qorace] = useQueries([
    is_arcade ? q_arcade_race({ rid }) : q_race({ rid }),
  ]);
  const race = useMemo(() => {
    let r = getv(qorace, "data.result");

    let err = qorace.data?.err;
    if (!nils(err)) set_race_err(qorace.data.err);

    if (nils(r)) return null;
    else {
      set_watching2d(true);
    }
    return r;
  }, [qorace.dataUpdatedAt]);
  const r = race;
  const c = r?.class;
  const cb = r?.cb;

  useEffect(() => {
    if (!nils(race_err)) {
      racect.update({
        loaded: false,
        race_err,
      });
    }
  }, [race_err]);

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    let e = get_race_rtstatus(r, now);
    return e;
  }, [now, jstr(r)]);

  const qs_hs_enabled = !nils(c) && !nils(cb) && rtstatus !== "open";
  const qs_hs = useStepQuery({
    // q_: q_hstats,
    q_: is_arcade ? q_arcade_race_hstats : q_bikeinfo,
    par_ar: (r?.hids || []).map((hid) => [
      { rid, hid, cb, class: c },
      { enabled: qs_hs_enabled },
    ]),
    lim: 3,
  });
  const hsob = useMemo(() => {
    const hsob = {};
    if (!qs_hs_enabled) return {};
    for (let i = 0; i < r?.hids.length; i++) {
      let q = qs_hs.qs[i];
      let hid = r.hids[i];
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      if (race.is_team == true) {
        let t = _.find(race.teamsmap, (e) => e.hids.includes(hid));
        d.team = t;
        d.skin = t.k == "A" ? skinmap["MMBLUE"] : skinmap["MMRED"];
      }
      hsob[hid] = d;
    }
    // console.log("hsob", hsob);
    return hsob;
  }, [jstr(_.map(qs_hs.qs, "dataUpdatedAt")), jstr(race)]);

  const starob = useMemo(() => {
    if (nils(r)) return {};

    const starob = _.chain(r.hids)
      .map((hid) => {
        let star =
          ((r.bluestars || []).includes(hid) ? 2 : 0) +
          ((r.yellowstars || []).includes(hid) ? 3 : 0);
        return [hid, star];
      })
      .fromPairs()
      .value();
    return starob;
  }, [jstr(r)]);

  const racerunenabled = ["live", "finished"].includes(rtstatus);
  const [qo_racerun] = useQueries([
    is_arcade
      ? q_arcade_racerun({ rid }, {})
      : q_racerun({ rid }, { enabled: racerunenabled }),
  ]);
  const racerun = useMemo(() => {
    let d = getv(qo_racerun, "data.result");
    if (nils(d)) return null;
    d = base64_to_json(d);
    return d;
  }, [qo_racerun.dataUpdatedAt]);

  useEffect(() => {
    if (nils(r)) return;
    if (nils(r.start_time) || nils(now)) return;
    if (rtstatus == "finished") return;
    let diff = moment(r.start_time).diff(moment(now), "seconds");

    if (diff <= 8 && qiserr(qo_racerun)) {
      qorace.refetch();
      qo_racerun.refetch();
    }
  }, [rtstatus, jstr(r?.start_time), now, qo_racerun.dataUpdatedAt]);

  const racerunhs = useMemo(() => {
    let hs = _.keyBy(racerun?.hs, "hid") || {};
    return hs;
  }, [racerun?.hs]);

  useEffect(() => {
    let now = nano();
    if (qissuccesss(qo_racerun)) {
      if (nils(watching2d_st) && now > nano(r.start_time)) {
        set_watching2d_st(nano(moment().add(5, "seconds").toISOString()));
      }
    }
    if (watching2d == false) {
      set_watching2d_st(null);
    }
  }, [watching2d, qo_racerun.dataUpdatedAt]);

  let rrrr = {
    race,
    hsob,
    racerunhs,
    starob,
  };
  const loaded = useMemo(() => {
    let [a, b, c, d] = [
      !_.isEmpty(race),
      !_.isEmpty(hsob),
      racerunenabled ? !_.isEmpty(racerunhs) : true,
      rtstatus == "open" ? true : !_.isEmpty(starob),
    ];
    let loaded = a && b && c && d && true;
    if (loaded) {
      // console.log("racerunhs", racerunhs);
    }
    return loaded;
  }, [jstr(rrrr)]);

  const skybox = useMemo(() => {
    // let s = Math.ceil(Math.random() * 8);
    // console.log("using skybox", s);
    // return s;
    return 9;
  }, []);

  const rcon = {
    rid,

    race,
    rtstatus,

    hsob,
    starob,
    racerun,
    racerunhs,

    watching2d,
    set_watching2d,

    skybox,

    loaded,

    race_err,
    set_race_err,
  };

  useEffect(() => {
    if (loaded) racect.update(rcon);
  }, [loaded, jstr(rcon)]);

  // useEffect(() => {
  //   console.log("rcon", rcon, rcon.loaded);
  // }, [rcon.loaded]);

  return (
    <>
      {!nils(race?.race_name) && (
        <Helmet>
          <title>{`3D Player - ${race?.race_name} FBike || DNA Racing `}</title>
        </Helmet>
      )}
    </>
  );
};
